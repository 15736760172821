import './home.css';

export default function Home() {
    return (
        <>
        <div className='buffer'></div>
        <div id="heroSection">
            <img src="images/unknown.png" />
            <div id="heroSectionText">
                <h1>Welcome to Boosted!</h1>
                <p>
                    Boosted is a platform empowering small, community-run Rocket League leagues to operate administrative tasks, render services to players, and showcase results.
                    The goal of the project is to create an interactive and enjoyable online presence in which players, team management, and league staff have the tools necessary to
                    carry out league functions without the burden of building and integrating these tools themselves.
                </p>
            </div>
        </div>
        <div className="centeredContent">
            <div id="boxes">
                <div>
                    <h2>What can Boosted offer?</h2>
                    <box>
                        <h3>Discord Tools and Integrations</h3>
                        <p>
                            Most community-run leagues are operated out of Discord. Boosted builds on that by authenticating users and players through their Discord account.
                            This guarantees that users can prove ownership of their account, can be uniquely identified, must be a valid member of the server, and can be
                            further authorized to perform the actions of their role in the community.
                        </p>
                    </box>
                    <box>
                        <h3>Impersonation/Account Validation</h3>
                        <p>
                            One of the largest challenges for any small, community-run league is validating player accounts on external platforms. Most leagues work on the
                            honor system, simply asking players to add names to an unvalidated list. Boosted goes a step further by requiring all players to authenticate to
                            their Epic Games account via Epic Account Services (EAS). Once the user has successfully authenticated, any external accounts linked to that Epic
                            account (Steam, Xbox, and PSN) will also automatically be identified. Boosted will then use this information to validate that those accounts only
                            belong to that player and that the player only plays on one of those accounts for their matches.
                        </p>
                    </box>
                    <box>
                        <h3>Player and Team Management</h3>
                        <p>
                            Boosted offers tools for creating and managing teams, rosters, franchises, free agents, and practice squads - the core entities required for any
                            league. This also includes placing teams in tiers, as well as recording, validating, and publishing player transactions.
                        </p>
                    </box>
                    <box>
                        <h3>Seasons, Schedules, and Matches</h3>
                        <p>
                            Boosted offers tools for Commissioners to define seasons, weeks, schedules, and matchups, including playoffs.
                        </p>
                    </box>
                    <box>
                        <h3>Score reporting and Stats</h3>
                        <p>
                            Boosted offers tools (including Ballchasing integration) for score reporting and generating statistics for determining rankings, tiering, and
                            championships.
                        </p>
                    </box>
                    <box>
                        <h3>What Boosted doesn't offer</h3>
                        <p>
                            Boosted only supplies a platform. It does not operate your league for you. It does not offer tools for marketing, branding,
                            website administration, or monetization. It is up to the Tournament Organizer or Commissioner to build on the tools and services offered through Boosted.
                        </p>
                        <p>
                            Boosted does not offer services to any league or tournament that violates Psyonix terms of service or community guidelines. Please see
                            Rocket League's <a href="https://esports.rocketleague.com/community-license/">Community License</a> and
                             <a href="https://esports.rocketleague.com/community-tournament-guidelines/"> Community Tournament Guidelines</a> for full details.
                        </p>
                    </box>
                </div>
            </div>
        </div>
        </>
    );
}
import React from 'react';

import './logo.css';

export default function Logo() {
    return (
        <a href="/">
            <span id="boosted-text">Boosted</span>
            <img id="boosted-image" src="images/boost.png" />
        </a>
    );
}
import MenuButton from '../menuButton/MenuButton';
import './menu.css';

export default function Menu({specs}) {
    var menuListItems = specs.map(x => {
        return <a key={x.id} href={'/' + x.id}>{x.text}</a>
    });
    
    return (
        <nav id="menu">
            <MenuButton />
            <div id="menu-list" className='box-shadow'>
                {menuListItems}
            </div>
        </nav>
    );
}
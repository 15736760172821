import './footer.css';

export default function Footer() {
    return (
        <footer>
            <div id="footer-copyright">
                &#169; 2022, Boosted. All rights reserved. Other brands or product names are the trademarks of their respective owners.
            </div>
            <div id="footer-tos">
                <a href="privacy">Privacy Policy</a> <a href="tos">Terms of Service</a>
            </div>
        </footer>
    );
}
import Logo from '../logo/Logo';
import Menu from '../menu/Menu';

import './header.css';

import specs from '../../specs'

export default function App() {
    return (
        <header>
            <Menu specs={specs} />
            <Logo />
        </header>
    );
}
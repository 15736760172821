export default [
    {
        id: "",
        text: "Home"
    },
    {
        id: "league-api",
        text: "Leagues"
    },
    {
        id: "staff-api",
        text: "Staff"
    },
    {
        id: "team-api",
        text: "Teams"
    },
    {
        id: "account-api",
        text: "Accounts"
    },
    {
        id: "match-api",
        text: "Schedules"
    }
];
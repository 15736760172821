import './menuButton.css'

export default function MenuIcon() {
    
    return (
        <div id="menu-button">   
            <div className="menu-icon-container">        
                <div className="menu-icon"></div>
                <div className="menu-icon"></div>
                <div className="menu-icon"></div>
            </div> 
        </div>

    );
}
import './disclaimer.css';

export default function Disclaimer() {
    return (
        <div className='fullWidth callout'>
            <div className='centeredContent'>
                <div id='disclaimer'>
                    <p className='bold'>
                        BOOSTED IS IN NO WAY SPONSORED, ENDORSED, OR ADMINISTERED BY, OR OTHERWISE ASSOCIATED WITH, PSYONIX LLC. THE INFORMATION PLAYERS PROVIDE IN CONNECTION
                        WITH BOOSTED IS BEING PROVIDED TO TOURNAMENT ORGANIZER AND NOT TO PSYONIX LLC.
                    </p>
                    <p className='bold'>
                        BY PARTICIPATING IN BOOSTED, TO THE EXTENT PERMITTED BY APPLICABLE LAW, PLAYERS AGREE TO RELEASE AND HOLD HARMLESS PSYONIX LLC, ITS PARENT COMPANY,
                        LICENSORS, ITS AND THEIR AFFILIATES, AND ITS AND THEIR EMPLOYEES, OFFICERS, DIRECTORS, AGENTS, CONTRACTORS, AND OTHER REPRESENTATIVES FROM ALL CLAIMS,
                        DEMANDS, ACTIONS, LOSSES, LIABILITIES, AND EXPENSES RELATED TO THE TOURNAMENT
                    </p>
                </div>
            </div>
        </div>
    );
}
import {
    BrowserRouter,
    Route,
    Routes
} from 'react-router-dom';
import { RedocStandalone } from 'redoc';

import Header from '../header/Header';
import Home from '../home/Home';
import Privacy from '../privacy/Privacy';
import Tos from '../tos/Tos';
import Disclaimer from '../disclaimer/Disclaimer';
import Footer from '../footer/Footer';

import specs from '../../specs';

export default function App() {
    const redocs = specs.map(x =>
        <Route key={x.id} path={`/${x.id}`} element={
            <>
            <div className='buffer'></div>
            <RedocStandalone specUrl={'/specs/' + x.id + '.yaml'} options={
                {
                    hideDownloadButton: true,
                    scrollYOffset: 50,
                    hideSingleRequestSampleTab: true
                }
            } />
            </>
        } />
    );

    return (
        <>
        <Header />
        <BrowserRouter>
            <Routes>
                <Route exact path='/' element={
                    <Home />
                } />
                {redocs}

                <Route exact path='/privacy' element={
                    <Privacy />
                } />
                <Route exact path='/privacy.html' element={
                    <Privacy />
                } />
                <Route exact path='/tos' element={
                    <Tos />
                } />
                <Route exact path='*' element={
                    <div className='buffer'>404</div>
                } />
            </Routes>
        </BrowserRouter>
        <Disclaimer />
        <Footer />
        </>
    );
}